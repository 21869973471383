import React, { useEffect } from "react";
import getParsedQuery from "helpers/parse";
import _ from "lodash";
import storage from "helpers/storage";
import { authConfirmAction } from "actions/auth";
import { compose } from "recompose";
import { connect } from "react-redux";

const enhance = compose(
  connect(() => {
    return {};
  })
);

const AuthProvider = enhance((props) => {
  const { dispatch } = props;

  const query = getParsedQuery();
  const token = _.get(query, ["token"], null);
  const activeTab = _.get(query, ["activeTab"], null);
  const server = _.get(query, ["server"], null);
  const selects = _.get(query, ["selects"], null);
  console.log(selects);

  window.dispatch = dispatch;
  window.activeTab = _.toNumber(activeTab);

  useEffect(() => {
    if (token && server) {
      storage("token").set(token);
      storage("server").set(server);
    }
  }, []);

  useEffect(() => {
    const storedToken = storage("token").get();
    const storedServer = storage("server").get();
    if (storedServer && storedToken) {
      dispatch(authConfirmAction());
    }
  }, []);

  const { children } = props;
  return (
        <>
            {children}
        </>
  );
});

export default AuthProvider;
