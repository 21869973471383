const storage = (key) => ({
  "set": (value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  "get": () => {
    const value = sessionStorage.getItem(key);
    return JSON.parse(value);
  },
  "remove": () => {
    return sessionStorage.removeItem(key);
  }
});

export default storage;
