import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Input } from "antd";

const Wrapper = styled(Input)`
  height: 55px;
  padding: 0 12px;
  color: rgb(44, 58, 80);
  width: ${({ fullWidth, width }) => fullWidth ? " 100%" : width || "fit-content"};
  background: rgb(241, 242, 245);
  border: 1px solid rgb(177, 186, 202) !important;
  border-radius: 6px;
  outline: none;
  box-shadow: none !important;
  font-family: 'Montserrat', sans-serif;

  transition: all 0.2s ease-in-out 0s;

  &::placeholder {
    color: #9ea4ae;
    line-height: 20px;
  }
`;

const TextField = (props) => {
  const { fullWidth, label, onChange, defaultValue } = props;
  const styles = () => {
    const stylesObject = {};
    if (fullWidth) {
      stylesObject.width = "100%";
    }
    return stylesObject;
  };
  return (
      <Wrapper onChange={(event) => {
        onChange(event.target.value);
      }} defaultValue={defaultValue} style={styles()} placeholder={label}/>
  );
};

TextField.propTypes = {
  "fullWidth": PropTypes.bool,
  "label": PropTypes.string,
  "onChange": PropTypes.func,
  "defaultValue": PropTypes.any
};

TextField.defaultProps = {
  "fullWidth": true
};

export default TextField;
