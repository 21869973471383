import React from "react";
import NotFoundBg from "img/page-not-found.png";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled("div")`
  background: #fdfdfd;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const Item = styled("div")`
  padding: 135px 100px;
  background: #fff;
  border: 1px #efefef solid;
  display: flex;
  align-items: center

`;

const Image = styled("div")`
  background: url(${NotFoundBg}) no-repeat center center;
  background-size: 120px;
  width: 200px;
  height: 200px;
  margin-right: 60px;
`;

const Text = styled("div")`
  color: #666;

  & h1 {
    font-size: 60px;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 40px;

    & span {
      display: block;
      font-size: 25px !important
    }
  }

  & p {
    font-size: 14px;
    margin: 5px 0;
    font-weight: 600;

    & a {
      font-weight: 600
    }
  }

`;

const NotFound = ({ message }) => {
  return (
        <Wrapper>
            <Item>
                <Image/>

                {
                    message
                      ? <Text>
                            {message}
                        </Text>
                      : <Text>
                            <h1>404 <span>ошибка</span></h1>
                            <p>Запрашиваемая страница не найдена</p>
                            <p>Вернуться на
                                <spanp>главную</spanp>
                            </p>
                        </Text>

                }
            </Item>
        </Wrapper>
  );
};

NotFound.propTypes = {
  "message": PropTypes.string
};

export default NotFound;
