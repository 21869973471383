import React from "react";
import { Modal as ANTD } from "antd";
import { X } from "react-feather";
import styled from "styled-components";
import PropTypes from "prop-types";

// Styles
const Header = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled("h1")`
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #333333;
`;

const Icon = styled("div")`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Content = styled("div")`
  font-size: 16px;
`;

// Component
function Modal (props) {
  const {
    open,
    title,
    styles,
    closable,
    onClose,
    onSubmit,
    children,
    okButtonProps,
    cancelText,
    ...restProps
  } = props;

  // Data

  const header = (
      <Header>
        <Title>
          {title}
        </Title>
        <Icon onClick={onClose}><X size={18}/></Icon>
      </Header>
  );
  const okButtonStyles = {
    "display": "none", ...okButtonProps
  };
  const cancelButtonStyles = {
    "background": "#dc3545", "border": "1px solid #dc3545", "color": "#fff"
  };
  const onOk = () => {
    if (onSubmit) {
      onSubmit(onClose);
    } else {
      onClose();
    }
  };

  // Render
  return (<ANTD

          okText={"Подтвердить"}
          zIndex={100000}
          open={open}
          style={styles}
          onOk={onOk}
          okButtonProps={{ "style": okButtonStyles }}
          cancelButtonProps={{ "style": cancelButtonStyles, "onClick": onClose }}
          cancelText={cancelText}
          title={header}
          closable={closable}
          {...restProps}
      >
        <Content>
          {children}
        </Content>
      </ANTD>
  );
}

Modal.propTypes = {
  "open": PropTypes.bool,
  "children": PropTypes.any,
  "title": PropTypes.any,
  "styles": PropTypes.object,
  "okButtonProps": PropTypes.object,
  "onClose": PropTypes.func,
  "onSubmit": PropTypes.func,
  "closable": PropTypes.bool,
  "cancelText": PropTypes.string
};

Modal.defaultProps = {
  "cancelText": "Отменить"
};

export default Modal;
