import React from "react";
import { Select as ANTD } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ChevronDown, X } from "react-feather";
import _ from "lodash";
import filter from "helpers/filter";

const { Option } = ANTD;

const StyledANTD = styled(ANTD)`

  & .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
`;

// Component
function Select (props) {
  // Props Data
  const {
    placeholder,
    onChange,
    width,
    data,
    mode,
    value,
    ...rest
  } = props;

  // Data
  const tagProps = mode === "tags"
    ? {
        "getPopupContainer": (trigger) => trigger.parentNode,
        "menuItemSelectedIcon": <X stroke="#333" size={16}/>,
        "tokenSeparators": [","]
      }
    : {};

  // Render
  return (
        <StyledANTD
            style={{
              width
            }}
            mode={mode}
            placeholder={placeholder}
            onChange={(selected) => {
              const filtered = mode === "tags"
                ? value
                  ? filter(data, "id", selected, true)
                  : filter(data, "id", selected, mode === "tags")
                : filter(data, "id", selected, mode === "tags")[0];
              onChange(filtered, selected);
            }}
            value={value}
            suffixIcon={<ChevronDown size={14}/>}
            {...rest}
            {...tagProps}
        >
            {
                _.map(data, (item, index) => {
                  const id = _.get(item, ["id"]);
                  const label = _.get(item, ["label"]);
                  return (
                        <Option key={index} value={id}>
                            {label}
                        </Option>
                  );
                })
            }
        </StyledANTD>
  );
}

Select.propTypes = {
  "label": PropTypes.string,
  "placeholder": PropTypes.string,
  "width": PropTypes.string,
  "mode": PropTypes.string,
  "onChange": PropTypes.func,
  "value": PropTypes.any,
  "data": PropTypes.array
};

Select.defaultProps = {
  "width": "200px"
};

export default Select;
