import React from "react";
import styled from "styled-components";

const MainContainer = styled("div")`
  margin: auto;
  padding: 20px;

  ${({ style }) => style}
`;

const Container = (props) => {
  return (
        <>
            <MainContainer {...props}/>
        </>
  );
};

export default Container;
