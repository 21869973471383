import React, { useEffect } from "react";
import ConstructorGrid from "./Grid";
import useFields from "hooks/useFields";
import useFetch from "hooks/useFetch";
import * as API from "constants/api";
import * as ACTION_TYPES from "constants/actionTypes";
import { compose } from "recompose";
import { connect } from "react-redux";
import { firstDayOfMonth, lastDayOfMonth } from "../../constants/defaults";

const enhance = compose(
  connect(() => {
    return {};
  })
);

const ConstructorContainer = enhance((props) => {
  const { dispatch } = props;
  const { fields, functions } = useFields(["columns", "model", "grouping", "dateRange", "reportName"]);

  useEffect(() => {
    functions.setInitialValues({
      "dateRange": [firstDayOfMonth, lastDayOfMonth]
    }, fields);
  }, []);

  const models = useFetch({
    dispatch,
    "api": API.REPORT_MANY_CONFIG,
    "actionType": ACTION_TYPES.FETCH_REPORT_MANY_CONFIG
  });

  const reportsConfig = useFetch({
    dispatch,
    "params": { "from_dashboard": true, "page_size": "10000" },
    "api": API.REPORT_CONFIG,
    "actionType": ACTION_TYPES.FETCH_REPORT_CONFIG
  });

  return (
      <ConstructorGrid
          dispatch={dispatch}
          models={models}
          functions={functions}
          reportsConfig={reportsConfig}
          fields={fields}
      />
  );
});

export default ConstructorContainer;
