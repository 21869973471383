import React, { useEffect, useState } from "react";
import { Select as ANTD } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ChevronDown } from "react-feather";
import _ from "lodash";
import axios from "helpers/axios";
import Loader from "components/Loader";

const { Option } = ANTD;

const StyledANTD = styled(ANTD)`

  & .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
`;

// Component
function MultiSelect (props) {
  // Props Data
  const {
    placeholder,
    value = [],
    onChange,
    keyPath,
    valuePath,
    api,
    params,
    getData,
    style
  } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getData) {
      getData(data);
    }
  }, [data]);

  const onFocus = () => {
    if (_.isEmpty(data)) {
      setLoading(true);

      axios().get(api, { params })
        .then((response) => {
          setData(_.get(response, ["data", "results"]) || _.get(response, ["data"]));
          setLoading(false);
          return Promise.resolve(_.get(response, ["data", "results"]) || _.get(response, ["data"]));
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  // Render
  return (
      <StyledANTD
          style={style}
          onChange={(selectedItems, option) => {
            onChange(selectedItems, option);
          }}
          mode={"multiple"}
          placeholder={placeholder}
          onFocus={onFocus}
          filterOption={false}
          onSearch={(search) => {
            setLoading(true);

            axios().get(api, { "params": { ...params, search } })
              .then((response) => {
                setData(_.get(response, ["data", "results"]) || _.get(response, ["data"]));
                setLoading(false);
                return Promise.resolve(_.get(response, ["data", "results"]) || _.get(response, ["data"]));
              })
              .catch(() => {
                setLoading(false);
              });
          }}
          value={value}
          notFoundContent={loading ? <Loader/> : "Nothing Found"}
          suffixIcon={<ChevronDown size={14}/>}
      >
        {
          _.map(data, (item, index) => {
            const id = _.get(item, keyPath);
            const label = _.get(item, valuePath, _.get(item, ["name"]));
            return (
                <Option key={index} value={id}>
                  {label}
                </Option>
            );
          })
        }
      </StyledANTD>
  );
}

MultiSelect.propTypes = {
  "getData": PropTypes.func,
  "api": PropTypes.string,
  "keyPath": PropTypes.string,
  "valuePath": PropTypes.string,
  "params": PropTypes.object,
  "style": PropTypes.object,
  "label": PropTypes.string,
  "placeholder": PropTypes.string,
  "onChange": PropTypes.func,
  "value": PropTypes.any,
  "data": PropTypes.array
};

MultiSelect.defaultProps = {
  "width": "200px",
  "valuePath": "label",
  "keyPath": "id"
};

export default MultiSelect;
