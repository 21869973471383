import React from "react";
import styled from "styled-components";
import SaveIcon from "img/save.svg";
import DBIcon from "img/db-icon.svg";
import { compose } from "recompose";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "helpers/axios";
import { useHistory, withRouter } from "react-router";
import * as ROUTES from "constants/routes";
import { useSnackbar } from "notistack";

const enhance = compose(withRouter, connect((state, props) => {
  const widgets = _.get(state, ["widgets"]);
  const pathname = _.get(props, ["location", "pathname"]);

  return {
    pathname, widgets
  };
}));

const MenuRow = styled("div")`
  display: flex;
  align-items: center;
`;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  padding: 1px 11px;
  position: relative;
  cursor: pointer;
  background: ${({ bgColor }) => bgColor || "#5869f0"};
  height: 49px;

  & > img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  & > span {
    color: #fff;
    cursor: pointer;
    //line-height: 50px;
    display: block;
    font-size: 13px;
    user-select: none;
  }
`;

const Menus = enhance((props) => {
  const { widgets, pathname } = props;

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const redirect = (path) => {
    history.push({
      "pathname": path
    });
  };
  const saveWidgets = () => {
    if (pathname === ROUTES.DASHBOARD_PATH) {
      const requestData = _.map(widgets, (item) => {
        return {
          "dashboard_stat": _.get(item, ["data", "id"]),
          "aspect_json": {
            "col_size": _.get(item, ["w"]),
            "row_size": _.get(item, ["h"]),
            "position": {
              "top": _.get(item, ["y"]), "left": _.get(item, ["x"])
            }
          }
        };
      });
      axios().post(`/main/dashboard/${window.activeTab}/set_aspect/`, requestData).then(() => {
        enqueueSnackbar("Успешно сохранено", {
          "autoHideDuration": 1000,
          "preventDuplicate": true,
          "variant": "success",
          "anchorOrigin": { "vertical": "top", "horizontal": "right" }
        });
      });
    } else {
      redirect(ROUTES.DASHBOARD_PATH);
    }
  };
  return (<MenuRow>
            <Wrapper
                onClick={saveWidgets}
                style={{
                  "background": "#3AC977FF"
                }}>
                <img style={{
                  "height": "20px", "width": "20px"
                }} src={SaveIcon} alt=""/>
                <span>{ROUTES.DASHBOARD_PATH === pathname ? "Сохранить изменения" : "Изменить  дашборд"}</span>
            </Wrapper>
            <Wrapper
                onClick={() => redirect(ROUTES.REPORTS_CONSTRUCTOR_PATH)}>
                <img style={{
                  "height": "20px", "width": "20px"
                }} src={DBIcon} alt=""/>
                <span>Скачать Excel </span>
            </Wrapper>
        </MenuRow>);
});

export default Menus;
