import React from "react";
import styled from "styled-components";
import person from "img/person.png";
import { compose } from "recompose";
import { connect } from "react-redux";
import _ from "lodash";
import storage from "helpers/storage";
import LogOutIcon from "img/log-out.svg";

const enhance = compose(
  connect((state) => {
    const authConfirm = _.get(state, ["authConfirm"]);
    return {
      authConfirm
    };
  })
);

const Wrapper = styled("div")`
  color: #fff;
  cursor: pointer;
  padding: 9px;
  padding-right: 170px !important;
  display: flex;
  align-items: center;
`;

const Icon = styled("div")`
  width: 30px;
  height: 30px;
  overflow: hidden;
  background: url(${person}) no-repeat center center;
  margin-right: 7px;
  border-radius: 50%;
  background-size: cover;
`;

const LogOut = styled("div")`
  margin-left: 10px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1px 11px;
  cursor: pointer;
  background: #5869f0;
  height: 49px;

  & > img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  & > span {
    color: #fff;
    cursor: pointer;
    //line-height: 50px;
    display: block;
    font-size: 13px;
    user-select: none;
  }

`;

const Info = styled("div")`
display: flex;
  flex-direction: column;
  margin-left: 5px;
  & > span {
    color: #fff;
    cursor: pointer;
    font-size: 13px;
  }
  & > span:first-child {
    font-size: 13px;
  }
  & > span:last-child {
    font-size: 12px;
  }
`;

const User = enhance((props) => {
  const { authConfirm } = props;
  const name = _.get(authConfirm, ["data", "firstName"], "") + " " + _.get(authConfirm, ["data", "secondName"], "");

  const handleSignOut = () => {
    const confirmation = window.confirm("Вы уверены, что хотите выйти?");
    if (confirmation) {
      storage("token").remove();
      storage("server").remove();
      window.location.href = "https://staging.ritm.uz/";
      window.close();
    }
  };

  return (
        <Wrapper>
            <Icon />
            <Info>
                <span>{name}</span>
                <span>{storage("server").get()}(2.0)</span>
            </Info>
            <LogOut
                href={"javascript:window.close()"}
                onClick={handleSignOut}
            >
                <img src={LogOutIcon} alt="Log Out"/>
                <span>
                    Вернуться назад
                </span>
            </LogOut>
        </Wrapper>
  );
});

export default User;
