import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button as ANTD } from "antd";

const Wrapper = styled(ANTD)`
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  transition: opacity 0.2s ease-in-out 0s;
  border: 1px solid transparent;
  height: 45px;
  user-select: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  padding: 0 20px;
  background: ${({ background }) => background || "#5869f0"} !important;
`;

const Button = (props) => {
  const { children, ...rest } = props;
  return (
        <Wrapper type={"primary"} {...rest}>
            {children}
        </Wrapper>
  );
};

Button.propTypes = {
  "children": PropTypes.any
};

export default Button;
