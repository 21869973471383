import _ from "lodash";

const filter = (data, by, target, checkArray) => {
  if (checkArray) {
    return _.filter(data, (item) => {
      return _.includes(target, _.get(item, by));
    });
  }
  return _.filter(data, (item) => {
    return _.get(item, by) === target;
  });
};

export default filter;
