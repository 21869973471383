import React, { useEffect, useRef, useState } from "react";
import Select from "components/Fields/Select";
import _ from "lodash";
import PropTypes from "prop-types";
import { defaultListPropTypes } from "helpers/defaults";
import getNeededArray from "helpers/getNeededArray";
import filter from "helpers/filter";
import Container from "components/Container";
import "./pivot.css";
import Fields from "components/Fields/Fields";
import Button from "components/Fields/Button";
import { Checkbox, DatePicker, Divider, Flex, List as ANTDList, Popover } from "antd";
import styled from "styled-components";
import axios from "helpers/axios";
import * as API from "constants/api";
import { constructorListSerializer, reSerializer, saveConstructorSerializer } from "serializers/constructor";
import { ChevronDown } from "react-feather";
import useDialog from "hooks/useDialog";
import Modal from "components/Modal";
import matchMerge from "helpers/matchMerge";
import Loader from "components/Loader";
import useFields from "hooks/useFields";
import MultiSelect from "components/Fields/MultiSelect";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import useCreate from "hooks/useCreate";
import TextField from "components/Fields/TextField";
import "@webdatarocks/webdatarocks/webdatarocks.css";
import Pivot from "@webdatarocks/react-webdatarocks";
import { collapse, expand } from "constants/icons";
import NewPivot from "./NewPivot";

const columnOptionKeys = {
  "id": "key", "label": "name", "is_columns": "show_in_select"
};

const modelOptionKeys = {
  "id": "model", "label": "name", "fields": "fields"
};

const Options = styled("div")`
  display: flex;
  max-height: 70vh;
  overflow: scroll;
  flex-direction: column;

  & > :not(:first-child) {
    margin-top: 8px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    scrollbar-width: none;
  }
`;

const Paper = styled("div")`
  padding: 10px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;

`;

const DropDown = styled("div")`
  display: flex;
  align-items: center;
  color: #5869f0;
  font-size: 14px;
  fill: #5869f0;
  cursor: pointer;

  * > :last-child {
    margin-left: 5px;
  }
`;

const Filters = styled("div")`

  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;

  & > * {
    padding-right: 10px;
    margin-bottom: 10px;
    width: 20%;
  }
`;

export const exportToExcel = (headers, body, fileName = "Excel") => {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  const formattedBody = _.map(body, (item) => {
    return _.map(headers, (header) => {
      const id = _.get(header, ["id"]);

      return _.get(item, [id]);
    });
  });

  const formattedHeaders = _.map(headers, (item) => _.get(item, ["label"]));

  const ws = XLSX.utils.json_to_sheet(formattedBody);

  XLSX.utils.sheet_add_aoa(ws, [formattedHeaders], { "origin": "A1" });

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const { RangePicker } = DatePicker;

const ConstructorGrid = (props) => {
  // Props
  const { fields, models, reportsConfig, functions } = props;

  // Hooks
  const [constructor, setConstructor] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const dialog = useDialog();
  const saveDialog = useDialog();
  const pivot = useRef(null);
  // Data
  const modelOptions = getNeededArray(_.get(models, ["data"]), modelOptionKeys);
  const filteredColumns = filter(_.get(fields, ["model", "value", "fields"]), "show_in_select", true);
  const filteredGrouping = filter(_.get(fields, ["model", "value", "fields"]), "show_in_group", true);
  const columnOptions = getNeededArray(filteredColumns, columnOptionKeys);
  const groupingOptions = getNeededArray(filteredGrouping, columnOptionKeys);
  const filterOptions = filter(filter(_.get(fields, ["model", "value", "fields"]), "show_in_filter", true), "type", "foreignkey");

  const {
    "fields": filterFields,
    "functions": filterFunctions
  } = useFields(_.map(filterOptions, (item) => _.get(item, ["key"])), filterOptions, (item, name) => {
    return _.get(item, ["key"]) === name;
  });

  const saveReport = useCreate({
    "thenActions": () => {
      const { onFetch } = reportsConfig;
      onFetch();
    },
    "serializer": saveConstructorSerializer,
    "api": API.REPORT_CONFIG
  });
  const groupingOnChange = _.get(fields, ["grouping", "onChange"]);
  const columnsOnChange = _.get(fields, ["columns", "onChange"]);

  const joinedHeaders = _.filter(headers, (item) => item && _.get(item, ["id"]));
  const columns = _.map(joinedHeaders, (item) => {
    return {
      "title": _.get(item, ["label"]),
      "dataIndex": _.get(item, ["id"]),
      "width": "5%"
    };
  });

  const report = {
    "dataSource": {
      "data": (() => {
        return _.map(constructor, (item) => {
          const items = _.map(columns, (column) => {
            const dataIndex = _.get(column, ["dataIndex"]);
            const title = _.get(column, ["title"]);
            return {
              [title]: _.get(item, dataIndex)
            };
          });
          return _.merge({}, ...items);
        });
      })()
    },
    "slice": {
      "rows": [
        { "uniqueName": "Category" }
      ],
      "columns": [
        { "uniqueName": "[Measures]" }
      ],
      "measures": [
        {
          "uniqueName": "Sales",
          "aggregation": "sum",
          "caption": "Sales"
        }
      ]
    },
    "options": {
      "showAggregationLabels": false
    },
    "formats": [{
      "name": "",
      "thousandsSeparator": ",",
      "decimalSeparator": ".",
      "decimalPlaces": 2,
      "maxSymbols": 20,
      "currencySymbol": "",
      "currencySymbolAlign": "left",
      "nullValue": " ",
      "infinityValue": "Infinity",
      "divideByZeroValue": "Infinity"
    }]
  };
  const [savedReport, setSavedReport] = useState(null);
  console.log(savedReport);
  // Handlers
  const onChecked = (value, group, name) => {
    if (group === "column") {
      columnsOnChange({
        ..._.get(fields, ["columns", "value"]), [name]: value
      });
    } else if (group === "grouping") {
      groupingOnChange({
        ..._.get(fields, ["grouping", "value"]), [name]: value
      });
    }
  };

  const onClick = () => {
    setLoading(true);
    axios().post(API.REPORT_MANY, constructorListSerializer(fields, filterFields))
      .then((response) => {
        const filteredGrouping = _.pickBy(_.get(fields, ["grouping", "value"]), (item) => {
          return item === true;
        });
        const filteredColumns = _.pickBy(_.get(fields, ["columns", "value"]), (item) => {
          return item === true;
        });
        const getGrouping = _.filter(groupingOptions, (item) => {
          return _.includes(Object.keys(filteredGrouping), _.get(item, ["id"]));
        });
        const getColumns = _.filter(columnOptions, (item) => {
          return _.includes(Object.keys(filteredColumns), _.get(item, ["id"]));
        });

        setHeaders(_.concat(getGrouping, getColumns));
        setConstructor(_.get(response, ["data"]));
        setLoading(false);

        return Promise.resolve(_.get(response, ["data"]));
      }).catch((response) => {
        setLoading(false);
        return _.get(response, ["data"]);
      });
  };

  useEffect(() => {
    const element = document.getElementsByClassName("wdr-scroll-pane")[0];
    if (element) {
      element.addEventListener("scroll", (ev) => {
        console.log(ev);
      });
    }
  }, []);

  const onSavedReportClick = (item) => {
    const model = _.get(item, ["code", "model", "key"]);
    const selectedFilters = _.filter(_.get(item, ["code", "filter"]), (obj) => _.get(obj, ["field_name", "value"]) !== "created_date");
    const dateFilters = _.filter(_.get(item, ["code", "filter"]), (obj) => _.get(obj, ["field_name", "value"]) === "created_date");

    const dateInitials = _.map(dateFilters, (date) => {
      return dayjs(_.get(date, ["value"]));
    });

    const modelName = _.get(item, ["code", "model", "name"]);
    const selectedModelFields = _.get(filter(modelOptions, "id", model)[0], ["fields"]);

    const staticGrouping = filter(selectedModelFields, "show_in_group", true);
    const staticColumns = filter(selectedModelFields, "show_in_select", true);

    const getTargetColumns = _.filter(matchMerge(staticColumns, _.get(item, ["code", "select"]), "key", "field_name"), i => _.get(i, ["field_name"]));
    const getTargetGrouping = _.filter(matchMerge(staticGrouping, _.get(item, ["code", "group_by"]), "key", "field_name"), i => _.get(i, ["field_name"]));

    const savedReportHeaders = _.filter(getNeededArray([...getTargetGrouping, ...getTargetColumns], {
      "id": "field_name", "label": "name", "is_columns": "show_in_select"
    }), (item) => _.get(item, ["id"]));
    setHeaders(savedReportHeaders);

    const setObject = (data) => {
      return _.map(data, (item) => {
        return {
          [_.get(item, ["key"])]: true
        };
      });
    };
    setLoading(true);
    axios().post(API.REPORT_MANY, reSerializer(_.get(item, ["code"])))
      .then((response) => {
        setConstructor(_.get(response, ["data"]));
        dialog.onClose();
        setSavedReport({
          ..._.get(item, ["code", "pivot"]),
          "dataSource": {
            "data": (() => {
              return _.map(_.get(response, ["data"]), (item) => {
                const items = _.map(savedReportHeaders, (column) => {
                  const dataIndex = _.get(column, ["id"]);
                  const title = _.get(column, ["label"]);
                  return {
                    [title]: _.get(item, dataIndex)
                  };
                });
                return _.merge({}, ...items);
              });
            })()
          }
        });

        setLoading(false);
        return Promise.resolve(_.get(response, ["data"]));
      })
      .catch((response) => {
        setLoading(false);
        dialog.onClose();
        return _.get(response, ["data"]);
      }).then(() => {
        const reportInitialValues = _.map(selectedFilters, (obj) => {
          const fieldName = _.get(obj, ["field_name", "value"]);
          return {
            [fieldName]: _.map(_.get(obj, ["valueIsArray"]), (item) => {
              return _.get(item, ["value"]);
            })
          };
        });

        filterFunctions.setInitialValues({
          ..._.merge({}, ...reportInitialValues)
        });
        functions.setInitialValues({
          "dateRange": dateInitials,
          "grouping": _.merge({}, ...setObject(getTargetGrouping)),
          "columns": _.merge({}, ...setObject(getTargetColumns)),
          "model": {
            "id": model,
            "fields": selectedModelFields,
            "label": modelName
          }
        }, fields);
      });
  };

  const customizeToolbar = (toolbar) => {
    const tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      tabs.unshift({
        "id": "wdr-tab-lightblue",
        "title": "Развернуть",
        "handler": () => {
          pivot.current.webdatarocks.expandAllData();
        },
        "icon": expand
      }, {
        "id": "wdr-tab-lightblue",
        "title": "Свернуть",
        "handler": () => {
          pivot.current.webdatarocks.collapseAllData();
        },
        "icon": collapse
      });
      return tabs;
    };
  };

  const groupingContent = (
        <Options>
            {_.map(groupingOptions, (item) => {
              const label = _.get(item, ["label"]);
              const id = _.get(item, ["id"]);
              const checked = _.get(fields, ["grouping", "value", id]);
              return (
                    <Checkbox
                        checked={checked}
                        onChange={(event) => onChecked(_.get(event, ["target", "checked"]), "grouping", id)}>{label}</Checkbox>
              );
            })}
        </Options>
  );
  const columnsContent = (
        <Options>
            {_.map(columnOptions, (item) => {
              const label = _.get(item, ["label"]);
              const id = _.get(item, ["id"]);
              const checked = _.get(fields, ["columns", "value", id]);
              return (<Checkbox
                    checked={checked}
                    onChange={(event) => onChecked(_.get(event, ["target", "checked"]), "column", id)}>{label}</Checkbox>);
            })}
        </Options>
  );
  const filtersContent = (
        <Filters>
            {
                _.map(filterFields, (item) => {
                  const name = _.get(item, ["name"]);
                  const fieldObj = _.find(filterOptions, { "key": name });
                  const label = _.get(fieldObj, ["name"]);

                  return (
                        <MultiSelect
                            api={API.MODEL_SEARCH_API}
                            params={{ "page_size": 10000, "m": name }}
                            placeholder={label}
                            {...item}
                        />
                  );
                })
            }
        </Filters>
  );

  const show = Math.random();
  return (
        <Container>
            <Paper>
                <Flex justify={"space-between"}>
                    <Fields>
                        <Select
                            width={"400px"}
                            placeholder={"Выбрать модель"}
                            data={modelOptions}
                            {..._.get(fields, ["model"])}
                        />

                        <RangePicker
                            stlye={{ "width": "400px" }}
                            placeholder={"Выбрать группировка"}
                            {..._.get(fields, ["dateRange"])}
                            format="YYYY-MM-DD"
                        />
                    </Fields>
                    <Fields>
                        <Button
                            background={"#ff8a65"}
                            onClick={saveDialog.onOpen}>
                            Сохранить
                        </Button>
                        {/* <Button */}
                        {/*    background={"#3ac977"} */}
                        {/*    disabled={_.isEmpty(constructor)} */}
                        {/*    onClick={() => exportToExcel(headers, constructor)}> */}
                        {/*    Скачать */}
                        {/* </Button> */}
                        <Button onClick={dialog.onOpen}>
                            Отчеты
                        </Button>
                    </Fields>
                </Flex>
                <Divider style={{ "margin": "10px" }}/>
                <Flex justify={"space-between"}>
                    <Flex gap={"20px"}>
                        <Popover
                            placement={"bottom"}
                            content={groupingContent}
                            title="Группировка"
                            trigger="click"
                            open={open}
                            onOpenChange={setOpen}
                        >
                            <DropDown>
                                <p>Группировка</p>
                                <ChevronDown size={17}/>
                            </DropDown>

                        </Popover>
                        <Popover
                            placement={"bottom"}
                            content={columnsContent}
                            title="Значения"
                            trigger="click"
                            open={open2}
                            onOpenChange={setOpen2}
                        >
                            <DropDown>
                                <p>Значения</p>
                                <ChevronDown size={17}/>
                            </DropDown>
                        </Popover>
                        <Popover
                            overlayClassName={"filters-popover"}
                            style={{
                              "width": "100%",
                              "background": "red"
                            }}
                            placement={"bottom"}
                            content={filtersContent}
                            title="Фильтр"
                            trigger="click"
                            open={open3}
                            onOpenChange={setOpen3}
                        >
                            <DropDown>
                                <p>Быстрый фильтр</p>
                                <ChevronDown size={17}/>
                            </DropDown>
                        </Popover>
                    </Flex>
                    <Button onClick={onClick}>
                        Применить
                    </Button>
                </Flex>
                <Divider style={{ "margin": "10px" }}/>
                {
                    loading
                      ? <Loader/>
                      : !show
                          ? (
                            <Pivot
                                ref={pivot}
                                beforetoolbarcreated={customizeToolbar}
                                report={savedReport || { ...report }}
                                toolbar={true}
                                width="100%"
                            />
                            )
                          : <NewPivot />
                }
            </Paper>
            <Modal
                title="Отчеты"
                cancelText={"Закрыть"}
                centered
                styles={{
                  "top": 0, "height": "calc(100vh - 117px)", "width": "fit-content"
                }}
                {...dialog}
            >
                {!loading

                  ? <ANTDList
                        style={{

                          "height": "80vh",
                          "overflow": "scroll"
                        }}
                        bordered
                        dataSource={_.get(reportsConfig, ["data", "results"])}
                        renderItem={(item) => (<ANTDList.Item onClick={() => onSavedReportClick(item)}>
                            {_.get(item, ["name"])}
                        </ANTDList.Item>)}
                    />
                  : <Loader/>}
            </Modal>
            <Modal
                closable={true}
                title="Сохранить таблицу"
                cancelText={"Закрыть"}
                centered
                okButtonProps={{
                  "display": "block"
                }}
                onSubmit={(func) => saveReport.onSubmit(func, fields, filterFields, pivot)}
                {...saveDialog}
            >
                <Fields
                    style={{
                      "margin": "40px 0"
                    }}
                >
                    <TextField
                        label={"Название таблицы"}
                        {..._.get(fields, ["reportName"])}
                    />
                </Fields>
            </Modal>
        </Container>
  );
};

ConstructorGrid.propTypes = {
  "fields": PropTypes.object,
  "functions": PropTypes.object,
  "dispatch": PropTypes.func,
  "models": PropTypes.shape({
    ...defaultListPropTypes
  }),
  "reportsConfig": PropTypes.shape({
    "onFetch": PropTypes.func,
    ...defaultListPropTypes
  })
};

export default ConstructorGrid;
