import _ from "lodash";

export const widgetsSerializer = (data) => {
  return _.map(data, (item, index) => {
    return {
      "w": _.get(item, ["aspect_json", "col_size"]),
      "h": _.get(item, ["aspect_json", "row_size"]),
      "x": _.get(item, ["aspect_json", "position", "left"]),
      "y": _.get(item, ["aspect_json", "position", "top"]),
      "data": {
        ...item
      }
    };
  });
};
