import React from "react";
import styled from "styled-components";
import logo from "img/logo.png";
import Menus from "./Menus";
import User from "./User";

const Wrapper = styled("div")`
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 99;
  position: fixed;
  background: #1C263D;
  box-shadow: 0 5px 10px 2px rgb(79, 113, 220, 0.3);
`;

const Container = styled("div")`
  height: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0 1.5rem;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Logo = styled("a")`
  background: #FFFFFF00 !important;

  & > img {
    width: 12px !important;
  }
`;

const SystemName = styled("div")`
  color: #fff;
  font-size: 22px;
  margin-left: 10px;
  font-weight: bold;
  `;

const Header = () => {
  return (
        <Wrapper>
            <Container>
                <Title>
                    <Logo>
                        <img src={logo} alt=""/>
                    </Logo>
                    <div className={"title"}>
                        <SystemName>
                  <span style={{ "whiteSpace": "nowrap" }}>
                      Ритм настройка виджетов
                  </span>
                        </SystemName>
                    </div>
                </Title>
                <Menus/>
                <User/>

            </Container>
        </Wrapper>
  );
};

export default Header;
