import * as API from "constants/api";
import axios from "helpers/axios";
import _ from "lodash";
import * as actionTypes from "constants/actionTypes";

export const authConfirmAction = () => {
  const payload = axios()
    .get(API.AUTH_CONFIRM)
    .then((response) => {
      return Promise.resolve(_.get(response, ["data"]));
    })
    .catch((response) => {
      return Promise.reject(_.get(response, ["data"]));
    });

  return {
    "type": actionTypes.AUTH_CONFIRM_FETCH,
    payload
  };
};
