import _ from "lodash";

const getNeededArray = (data, select) => {
  const keys = Object.keys(select);
  return _.map(data, (item) => {
    return _.merge({}, ..._.map(keys, (key) => {
      const path = _.get(select, key);

      return {
        [key]: _.get(item, path)
      };
    }));
  });
};

export default getNeededArray;
