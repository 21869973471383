import axios from "axios";
import sprintf from "sprintf";
import storage from "./storage";
import { errorDialogOpen } from "../actions/error";

const axiosRequest = () => {
  const token = storage("token").get();
  const server = storage("server").get();

  const API_HOST = `${"https://" + server + ".ritm.uz"}`;

  const API_ROOT = "api";

  const FORMED_URL = `${API_HOST}/ru/${API_ROOT}/v1`;

  axios.defaults.baseURL = sprintf(FORMED_URL, "");

  axios.defaults.headers.common.Authorization = `Token ${token}`;
  axios.defaults.headers.common["cache-control"] = "no-cache";
  axios.interceptors.response.use(
    response => response,
    ({ "response": { data } }) => {
      window.dispatch(errorDialogOpen(data));
    }
  );
  return axios;
};

export default axiosRequest;
