import React from "react";
import DashboardGrid from "./Grid";

const DashboardContainer = () => {
  return (
      <DashboardGrid/>
  );
};

export default DashboardContainer;
