import { useEffect, useState } from "react";
import axios from "helpers/axios";
import _ from "lodash";
import sprintf from "sprintf";

const useFetch = (props) => {
  const {
    api,
    params,
    actionType,
    withFetchFunc,
    dispatch,
    thenActions,
    fetchSerializer,
    dependencies = []
  } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!withFetchFunc) {
      setLoading(true);
      axios().get(api, { params })
        .then((response) => {
          setLoading(false);
          if (thenActions) {
            thenActions(_.get(response, ["data"]));
          }
          setData(fetchSerializer ? fetchSerializer(_.get(response, ["data"])) : _.get(response, ["data"]));
          dispatch({
            "type": actionType,
            "payload": fetchSerializer ? fetchSerializer(Promise.resolve(_.get(response, ["data"]))) : Promise.resolve(_.get(response, ["data"]))
          });
        })
        .catch((response) => {
          setLoading(false);
          setError(_.get(response, ["data"]));
          return _.get(response, ["data"]);
        });
    }
  }, [...dependencies]);

  const onFetch = (id) => {
    setLoading(true);
    axios().get(id ? sprintf(api, id) : api, { params }).then((response) => {
      setLoading(false);
      setData(fetchSerializer ? fetchSerializer(_.get(response, ["data"])) : _.get(response, ["data"]));
      dispatch({
        "type": actionType,
        "payload": fetchSerializer ? fetchSerializer(_.get(response, ["data"])) : Promise.resolve(response, ["data"])
      });
    })
      .catch((response) => {
        setLoading(false);
        setError(_.get(response, ["data"]));
        return _.get(response, ["data"]);
      });
  };

  const defaultResponse = {
    data,
    error,
    loading
  };

  return { ...defaultResponse, onFetch };
};

export default useFetch;
