import * as ACTION_TYPES from "constants/actionTypes";

export const errorDialogOpen = payload => {
  return {
    "type": ACTION_TYPES.ERROR_OPEN,
    payload
  };
};

export const errorDialogClose = (payload) => ({
  "type": ACTION_TYPES.ERROR_CLOSE,
  payload
});
