import _ from "lodash";
import Tabs from "components/Tabs";
import { Tabs as ANTDTabs } from "antd";
import ResponsiveGrid from "components/ResponsiveGrid/ResponsiveGrid";
import React, { useEffect, useRef, useState } from "react";
import useFields from "hooks/useFields";
import useDialog from "hooks/useDialog";
import { compose } from "recompose";
import { connect } from "react-redux";
import useFetch from "hooks/useFetch";
import * as API from "constants/api";
import * as actionTypes from "constants/actionTypes";
import Loader from "components/Loader";
import { widgetsSerializer } from "serializers/widgets";
import styled from "styled-components";
import Button from "components/Fields/Button";
import Modal from "components/Modal";
import MultiSelect from "../../../components/Fields/MultiSelect";
import axios from "../../../helpers/axios";

const enhance = compose(connect((state) => {
  const widgets = _.get(state, ["widgets"]);
  return {
    widgets
  };
}));

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
`;

const Filters = styled("div")`
  height: 200px;
  display: flex;

  flex-direction: column;
  padding-top: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  & > * {
    padding-right: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
`;

const DashboardGrid = enhance((props) => {
  const { dispatch, pageLoading, widgets } = props;
  const { fields, functions } = useFields(["widgets", "tab"]);

  const dialog = useDialog();

  const filtersRef = useRef(null);

  const [activeTab, setActiveTab] = useState(window.activeTab);

  const [selectWidgets, setSelectWidgets] = useState([]);

  useEffect(() => {
    functions.setInitialValues({
      "tab": "all"
    });
  }, []);

  const dashboards = useFetch({
    dispatch,
    "thenActions": (response) => {
      const id = _.get(_.first(response), ["id"]);
      setActiveTab(id);
    },
    "api": API.MAIN_DASHBOARDS_LIST,
    "actionType": actionTypes.FETCH_MAIN_DASHBOARDS_LIST
  });

  const widgetsNew = useFetch({
    dispatch,
    "withFetchFunc": true,
    "fetchSerializer": widgetsSerializer,
    "api": API.MAIN_DASHBOARD_WIDGETS_LIST,
    "actionType": actionTypes.FETCH_MAIN_DASHBOARD_WIDGETS_LIST
  });

  useEffect(() => {
    widgetsNew.onFetch(activeTab);
  }, [activeTab]);

  const cols = (number) => {
    const steps = [];
    for (let i = 1; i <= number; i++) {
      steps.push(<div key={i} className={"dashboard-item"}/>);
    }
    return steps;
  };

  const getChild = (params) => {
    return (
          <MultiSelect
              style={{ "width": "100%" }}
              getData={setSelectWidgets}
              placeholder={"Выберите виджеты"}
              keyPath={"key"}
              valuePath={"title"}
              params={params}
              {..._.get(fields, ["widgets"])}
              api={API.WIDGETS_LIST}
          />
    );
  };
  return (
        <>
            <Modal
                width={"700px"}
                title="Добавить виджеты"
                centered
                onSubmit={(onClose) => {
                  const widgetsValue = _.get(fields, ["widgets", "value"], []);
                  const filteredWidgets = _.filter(selectWidgets, (item) => {
                    return _.includes(widgetsValue, _.get(item, ["key"]));
                  });
                  const requestData = _.map(filteredWidgets, (item) => {
                    return {
                      "key": _.get(item, ["key"]),
                      "type": _.get(item, ["type"])
                    };
                  });
                  axios().post("/main/dashboard/bulk_add_stat/", { "stats": requestData, "dashboard": activeTab }).then((response) => {
                    onClose();
                    widgetsNew.onFetch(activeTab);
                    functions.setInitialValues({
                      "widgets": []
                    });
                    return Promise.resolve(response);
                  }).catch((error) => {
                    onClose();
                    return Promise.reject(error);
                  });
                }}
                okButtonProps={{ "display": "block" }}
                {...dialog}
            >
                <Filters ref={filtersRef}>
                    <ANTDTabs
                        {..._.get(fields, ["tab"])}
                        activeKey={_.get(fields, ["tab", "value"])}
                        defaultActiveKey="all"
                        more={{
                          "getPopupContainer": () => filtersRef.current
                        }}
                        items={[
                          {
                            "label": "Все",
                            "key": "all",
                            "children": getChild({})
                          },
                          {
                            "label": "Системные отчеты",
                            "key": "system",
                            "children": getChild({ "type": "system" })
                          },
                          {
                            "label": "Таблицы с конструктора",
                            "key": "constructor",
                            "children": getChild({ "type": "constructor", "report_type": "table" })
                          },
                          {
                            "label": "Виджеты с конструктора",
                            "key": "widgets",
                            "children": getChild({ "type": "constructor", "report_type": "widget" })
                          },
                          {
                            "label": "Другие виджеты",
                            "key": "others",
                            "children": getChild({ "type": "static_widget", "report_type": "static_widget" })
                          }
                        ]}
                    />
                </Filters>
            </Modal>
            <Row>
                <Tabs
                    dispatch={dispatch}
                    activeTab={activeTab}
                    handleTabClick={(id) => {
                      setActiveTab(id);
                    }}
                    tabs={_.concat(_.get(dashboards, ["data"]))}/>
                {
                    activeTab
                      ? <Button onClick={dialog.onOpen}>
                        Добавить виджет
                    </Button>
                      : ""
                }
            </Row>
            <div className={"widget-container-fixed"}>
                {
                    pageLoading || widgets.loading || widgetsNew.loading
                      ? <Loader/>
                      : activeTab
                        ? <>
                            <div className={"dashboard-background"}>
                                {cols(900)}
                            </div>
                            <ResponsiveGrid dispatch={dispatch} widgets={widgets}/>
                        </>
                        : <></>
                }
            </div>
        </>
  );
});

export default DashboardGrid;
