import React from "react";
import _ from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled("div")`
  margin: 10px;
  background: #8091C06C;
  width: fit-content;
  padding: 5px;
  border-radius: 8px;
  position: relative;
`;

const TabsWrapper = styled("div")`
  display: flex;

`;

const Tab = styled("div")`
  border-radius: 8px;
  min-height: 42px;
  padding: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;

  width: fit-content;
  font-size: 12px;
  transition: background-color 0.3s;

  &.active {
    background: #1C263D;
    color: #fff;
  }
`;

const Name = styled("div")`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Tabs = (props) => {
  const
    {
      tabs = [],
      activeTab,
      handleTabClick
    } =
            props;
  return (
        <Container>
            <TabsWrapper>
                {_.map(tabs, (tab) => {
                  const id = _.get(tab, ["id"]);
                  const name = _.get(tab, ["name"]);
                  const { onClick, ...props } = _.get(tab, ["props"], {});
                  return (
                            <Tab
                                key={id}
                                className={`tab ${activeTab === id ? "active" : ""}`}
                                {...props}
                            >
                                <Name
                                    onClick={() => {
                                      window.activeTab = id;
                                      if (onClick) {
                                        onClick();
                                      } else {
                                        handleTabClick(id);
                                      }
                                    }}
                                >
                                    {name}
                                </Name>

                            </Tab>
                  );
                })}
            </TabsWrapper>
        </Container>
  );
};

Tabs.propTypes = {
  "tabs": PropTypes.array,
  "handleTabClick": PropTypes.func,
  "activeTab": PropTypes.any,
  "dispatch": PropTypes.func
};

export default Tabs;
