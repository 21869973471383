import * as actionTypes from "constants/actionTypes";
import errorDialog from "helpers/openErrorDialog";

export const defaultState = [];

const widgetReducer = () => {
  return errorDialog(defaultState, {
    [`${actionTypes.ADD_WIDGET}`] (state, { payload }) {
      return [
        ...state,
        payload
      ];
    },
    [`${actionTypes.UPDATE_WIDGET}`] (state, { payload }) {
      return payload;
    },
    [`${actionTypes.FETCH_MAIN_DASHBOARD_WIDGETS_LIST}`] (state, { payload }) {
      return payload;
    }
  });
};

export default widgetReducer;
