import * as actionTypes from "constants/actionTypes";
import createReducer from "helpers/createReducer";

export const defaultState = {
  "open": false,
  "message": "Message",
  "type": "success",
  "autoHideDuration": 3000
};

const snackbarReducer = () => {
  return createReducer(defaultState, {
    [`${actionTypes.SNACKBAR_OPEN}`] (state, { payload }) {
      return {
        ...state,
        ...payload,
        "open": true
      };
    },

    [`${actionTypes.SNACKBAR_CLOSE}`] (state) {
      return {
        ...state,
        "type": "success",
        "open": false
      };
    }
  });
};

export default snackbarReducer;
