import { useEffect, useRef, useState } from "react";
import _ from "lodash";

const useFields = (names = [], arr, match) => {
  const [values, setValues] = useState({});
  const [valueObj, setValueObj] = useState({});
  const prevValuesRef = useRef();

  useEffect(() => {
    prevValuesRef.current = values;
  }, [values]);

  const fields = _.map(names, (item) => {
    const onChange = (value, obj) => {
      setValues({
        ...values,
        [item]: value
      });
      setValueObj({
        ...valueObj,
        [item]: obj
      });
    };

    if (match && arr) {
      const itemData = _.find(arr, (obj) => {
        return match(obj, item);
      });
      return {
        [item]: {
          "data": itemData,
          "value": _.get(values, [item], null),
          "valueData": _.get(valueObj, [item], null),
          "name": item,
          "prevValue": _.get(prevValuesRef, ["current", item], null),
          onChange
        }
      };
    } else {
      return {
        [item]: {
          "value": _.get(values, [item], null),
          "name": item,
          "valueData": _.get(valueObj, [item], null),
          "prevValue": _.get(prevValuesRef, ["current", item], null),
          onChange
        }
      };
    }
  });

  const setInitialValues = (initials) => {
    setValues(initials);
  };

  return { "functions": { setInitialValues }, "fields": _.merge({}, ...fields) };
};

export default useFields;
