import { BrowserRouter as Router, Switch } from "react-router-dom";
import React from "react";
import { Redirect, Route } from "react-router";
import * as ROUTES from "constants/routes";
import NotFound from "components/NotFound";
import MultiRouter from "router/MultiRouter";
import routes from "router";
import PropTypes from "prop-types";

const RoutingProvider = (props) => {
  const { children } = props;
  return (
        <>
            <Router>
                {children}
                <Switch>
                    <Redirect
                        path={ROUTES.ROOT_PATH}
                        to={ROUTES.DASHBOARD_PATH}
                        exact
                    />
                    {routes.map((route, key) => (
                        <MultiRouter key={key} {...route} />
                    ))}
                    <Route path={ROUTES.NOT_FOUND_PATH} component={NotFound}/>
                </Switch>
            </Router>
        </>
  );
};

RoutingProvider.propTypes = {
  "children": PropTypes.any
};

export default RoutingProvider;
