import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Modal } from "antd";
import styled from "styled-components";
import { X, XOctagon } from "react-feather";
import _ from "lodash";
import PropTypes from "prop-types";
import { errorDialogClose } from "actions/error";

// Enhance
const enhance = compose(
  connect((state) => {
    const open = _.get(state, ["error", "open"]);
    const message = _.get(state, ["error", "message"]);
    const data = _.get(state, ["error", "data"]);
    return {
      open,
      data,
      message
    };
  })
);

// Styles
const Header = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled("h1")`
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #333333;
`;

const Icon = styled("div")`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Content = styled("div")`
  & p {
    font-size: 16px;
  }

  margin-top: 27px;
  & svg {
    margin-right: 8px;
    margin-top: 2px;
  }

`;

const Ul = styled("ul")`
  list-style: none;
`;

const Li = styled("li")`
  & > div {
    width: 30px;
    margin-right: 5px;
  }

  display: flex;
  align-items: flex-start;
`;

function Error (props) {
  const {
    open,
    message,
    data,
    dispatch
  } = props;
  const okButtonStyles = {
    "background": "#dc3545",
    "border": "1px solid #dc3545",
    "width": "80px"
  };
    // Handlers
  const handleClose = () => {
    dispatch(errorDialogClose());
  };
    // Data
  const header = (
        <Header>
            <Title>
                {"Ошибка"}
            </Title>
            <Icon onClick={handleClose}><X size={18}/></Icon>
        </Header>
  );
  return (
        <Modal
            okText={"ОК"}
            zIndex={100000}
            centered
            open={open}
            cancelButtonProps={{ "style": { "display": "none" } }}
            okButtonProps={{ "style": okButtonStyles }}
            onOk={handleClose}
            title={header}
            closable={false}
        >
            <Content>
                {
                    _.isEmpty(data) || !_.isObject(data)
                      ? <p>{_.toString(message)}</p>
                      : <Ul>
                            {
                                _.map(Object.keys(data), (item) => {
                                  return (
                                        <Li>
                                            <div>
                                                <XOctagon stroke="#dc3545" size={25}/>
                                            </div>
                                            {_.toString(item)}: {JSON.stringify(_.get(data, item), null, "\t")}
                                        </Li>
                                  );
                                })
                            }
                        </Ul>
                }
            </Content>
        </Modal>
  );
}

Error.propTypes = {
  "open": PropTypes.bool,
  "message": PropTypes.string,
  "data": PropTypes.any,
  "dispatch": PropTypes.func
};

export default enhance(Error);
