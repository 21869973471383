import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import createStore from "./store/createStore";
import Rx from "rxjs";
import { setObservableConfig } from "recompose";
import moment from "moment";
import rxjsconfig from "recompose/rxjsObservableConfig";
import { Provider } from "react-redux";

const store = createStore();
window.store = store;
setObservableConfig({
  // Converts a plain ES observable to an RxJS 5 observable
  "fromESObservable": Rx.Observable.from
});
setObservableConfig(rxjsconfig);
moment.locale("ru");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <App
            store={store}
        />
    </Provider>
);
