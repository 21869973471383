import { reducer as formReducer } from "redux-form";
import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import snackbarReducer from "./snackbarReducer";
import widgetReducer from "./widgetReducer";
import * as actionTypes from "constants/actionTypes";
import * as STATES from "constants/states";
import createThunkReducer from "helpers/createThunkReducer";
import errorReducer from "./errorReducer";

const rootReducer = combineReducers({
  [STATES.AUTH_CONFIRM]: createThunkReducer(actionTypes.AUTH_CONFIRM_FETCH),
  [STATES.CONSTRUCTOR]: createThunkReducer(actionTypes.FETCH_REPORT_CONFIG),
  "widgets": widgetReducer(),
  "snackbar": snackbarReducer(),
  "error": errorReducer(),
  "form": formReducer,
  "routing": routerReducer
});

export default rootReducer;
