import { useState } from "react";
import axios from "helpers/axios";
import _ from "lodash";

const useCreate = (props) => {
  const { api, thenActions, serializer = () => {} } = props;
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onSubmit = (onClose, ...dataProps) => {
    setLoading(true);
    axios().post(api, { ...serializer(dataProps) })
      .then((response) => {
        setData(_.get(response, ["data"]));
        setLoading(false);
        if (thenActions) {
          thenActions();
        }
        if (onClose) {
          onClose();
        }
        return Promise.resolve(_.get(response, ["data"]));
      }).catch((response) => {
        setLoading(false);
        setError(_.get(response, ["data"]));
        if (onClose) {
          onClose();
        }
        return _.get(response, ["data"]);
      });
  };
  return {
    onSubmit,
    data,
    loading,
    error,
    api
  };
};
export default useCreate;
