import * as actionTypes from "constants/actionTypes";
import axios from "helpers/axios";
import * as API from "constants/api";
import _ from "lodash";
import sprintf from "sprintf";

export const addWidgetAction = (payload) => {
  return {
    "type": actionTypes.ADD_WIDGET,
    payload
  };
};
export const updateWidgetAction = (payload) => {
  return {
    "type": actionTypes.UPDATE_WIDGET,
    payload
  };
};

export const deleteWidgetAction = (payload) => {
  return {
    "type": actionTypes.UPDATE_WIDGET,
    payload
  };
};

export const deleteDashboardAction = (id) => {
  const payload = axios()
    .delete(sprintf(API.DASHBOARD_DELETE, _.toNumber(id)))
    .then((response) => {
      return Promise.resolve(_.get(response, ["data"]));
    })
    .catch((response) => {
      return Promise.reject(_.get(response, ["data"]));
    });

  return {
    "type": actionTypes.UPDATE_DASHBOARD,
    payload
  };
};
