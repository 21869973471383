import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import rootReducer from "reducers";

const createStoreC = (initialState) => {
  const middleware = [
    promiseMiddleware(),
    thunkMiddleware
  ];

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-undef
    const createLogger = require("redux-logger");
    middleware.push(createLogger({ "collapsed": true }));
  }

  return createStore(rootReducer, initialState, applyMiddleware(...middleware));
};

export default createStoreC;
