import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles/main.css";
import Header from "./components/Header/Header";

import AuthProvider from "./providers/AuthProvider";
import RoutingProvider from "./providers/RoutingProvider";
import { ConfigProvider as ThemeProvider } from "antd";
import { theme } from "./styles/theme";
import Error from "./components/Error/Error";
import { SnackbarProvider } from "notistack";

const App = () => {
  console.error = () => {

  };
  console.warn = () => {

  };

  console.log("VERSION: 1.1.1");

  return (
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <RoutingProvider>
                        <Error/>
                        <Header/>
                    </RoutingProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </AuthProvider>
  );
};

export default App;
