import * as ROUTES from "constants/routes";
import storage from "helpers/storage";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import NotFound from "components/NotFound";
import Dashboard from "pages/Dashboard";
import ConstructorContainer from "pages/Constructor";

// Is auth user
export const userIsAuth = connectedRouterRedirect({
  "authenticatedSelector": () => {
    const token = storage("token").get();
    return token && true;
  },
  "redirectPath": ROUTES.NOT_FOUND_PATH,
  "wrapperDisplayName": "UserIsAuthenticated"
});

export default [
  // Sign in
  {
    "path": ROUTES.DASHBOARD_PATH,
    "component": userIsAuth(Dashboard)
  },
  {
    "path": ROUTES.REPORTS_CONSTRUCTOR_PATH,
    "component": userIsAuth(ConstructorContainer)
  },
  {
    "path": "*",
    "component": NotFound
  }
];
