import _ from "lodash";

const matchMerge = (data, array, path = "field_name", target = "id") => {
  return _.merge([], ..._.map(data, (item) => {
    return _.map(array, (obj) => {
      if (_.get(item, path) === _.get(obj, target)) {
        return {
          ...item,
          ...obj
        };
      }
    });
  }));
};

export default matchMerge;
