import _ from "lodash";
import moment from "moment";
import dayjs from "dayjs";

const firstDayOfMonth = moment().format("YYYY-MM-01");
const lastDay = moment().daysInMonth();
const lastDayOfMonth = moment().format("YYYY-MM-" + lastDay);

export const constructorListSerializer = (data, filterFields) => {
  const endDate = _.get(data, ["dateRange", "value", "1", "$d"]);
  const beginDate = _.get(data, ["dateRange", "value", "0", "$d"]);
  const filteredObj = _.pickBy(filterFields, childObj => _.get(childObj, "value") !== null);
  const filteredGrouping = _.pickBy(_.get(data, ["grouping", "value"]), (item) => {
    return item === true;
  });
  const filteredColumns = _.pickBy(_.get(data, ["columns", "value"]), (item) => {
    return item === true;
  });
  return {
    "optimization": {},
    "group_by": _.map(Object.keys(filteredGrouping), (item) => {
      return {
        "type": "vertical",
        "field_name": item
      };
    }),
    "select": [
      ..._.map(Object.keys(filteredColumns), (item) => {
        return {
          "field_name": item
        };
      }),
      ..._.map(Object.keys(filteredGrouping), (item) => {
        return {
          "type": "vertical",
          "field_name": item
        };
      })
    ],
    "filter": [
      {
        "value": beginDate ? dayjs(beginDate).format("YYYY-MM-DD") : firstDayOfMonth,
        "field_name": {
          "value": "created_date"
        },
        "operation": "greater_than"
      },
      {
        "value": endDate ? dayjs(endDate).format("YYYY-MM-DD") : lastDayOfMonth,
        "field_name": {
          "value": "created_date"
        },
        "operation": "less_than"
      },
      ..._.filter(_.map(filteredObj, (item) => {
        const name = _.get(item, ["name"]);
        const value = _.get(item, ["value"]);
        return {
          "field_name": {
            "id": name,
            "value": name
          },
          "operation": "equal",
          "value": _.join(value, "-")
        };
      }), (item) => {
        return _.get(item, ["value"]) !== "" && _.get(item, ["value"]);
      })
    ],
    "model": _.get(data, ["model", "value", "id"]) || "order_product",
    "charts": [],
    "report_type": "table"
  };
};

export const reSerializer = (data) => {
  return {
    ...data,
    "optimization": {},
    "model": _.get(data, ["model", "key"])
  };
};

export const saveConstructorSerializer = (props) => {
  const fields = props[0];
  const filterFields = props[1];
  const pivot = props[2];
  const filteredObj = _.pickBy(filterFields, childObj => _.get(childObj, "value") !== null && !_.isEmpty(_.get(childObj, "value")));
  const beginDate = _.get(fields, ["dateRange", "value", "0"]);
  const endDate = _.get(fields, ["dateRange", "value", "1"]);
  const grouping = _.pickBy(_.get(fields, ["grouping", "value"]), value => value);
  const columns = _.pickBy(_.get(fields, ["columns", "value"]), value => value);
  const report = pivot.current.webdatarocks.getReport();
  delete _.get(report, ["dataSource"]);
  return {
    "code": {
      "pivot": {
        ...pivot.current.webdatarocks.getOptions(),
        ...report
      },
      "charts": [],
      "model": {
        "key": _.get(fields, ["model", "value", "id"]),
        "name": _.get(fields, ["model", "value", "label"])
      },
      "report_type": "table",
      "filter": [
        {
          "field_name": { "value": "created_date" },
          "operation": "greater_than",
          "value": beginDate ? dayjs(beginDate).format("YYYY-MM-DD") : firstDayOfMonth
        },
        {
          "field_name": { "value": "created_date" },
          "operation": "less_than",
          "value": beginDate ? dayjs(endDate).format("YYYY-MM-DD") : lastDayOfMonth
        },
        ..._.map(filteredObj, (item) => {
          return {
            "field_name": { "id": _.get(item, ["name"]), "value": _.get(item, ["name"]) },
            "operation": "equal",
            "value": _.join(_.get(item, ["value"]), "-"),
            "valueIsArray": _.map(_.get(item, ["valueData"]), (item) => {
              return {
                "value": _.get(item, ["value"]),
                "text": _.get(item, ["children"])
              };
            })
          };
        })
      ],
      "group_by": _.map(Object.keys(grouping), (item) => {
        return {
          "field_name": item,
          "type": "vertical"
        };
      }),
      "select": [
        ..._.map(Object.keys(grouping), (item) => {
          return {
            "field_name": item,
            "type": "vertical"
          };
        }),
        ..._.map(Object.keys(columns), (item) => {
          return {
            "field_name": item
          };
        })
      ]
    },
    "model": _.get(fields, ["model", "value", "id"]),
    "name": _.get(fields, ["reportName", "value"]),
    "report_type": "table"
  };
};
