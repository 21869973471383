export const ERROR = "ERROR";
export const ERROR_OPEN = `${ERROR}_OPEN`;
export const ERROR_CLOSE = `${ERROR}_CLOSE`;
export const SNACKBAR = "SNACKBAR";
export const SNACKBAR_OPEN = `${SNACKBAR}_OPEN`;
export const SNACKBAR_CLOSE = `${SNACKBAR}_CLOSE`;
export const ADD_WIDGET = "ADD_WIDGET";
export const UPDATE_WIDGET = "UPDATE_WIDGET";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const AUTH_CONFIRM_FETCH = "AUTH_CONFIRM_FETCH";
export const FETCH_MAIN_DASHBOARDS_LIST = "FETCH_MAIN_DASHBOARDS_LIST";
export const FETCH_MAIN_DASHBOARD_WIDGETS_LIST = "FETCH_MAIN_DASHBOARD_WIDGETS_LIST";
export const FETCH_REPORT_MANY_CONFIG = "FETCH_REPORT_MANY_CONFIG";
export const FETCH_REPORT_CONFIG = "FETCH_REPORT_CONFIG";
