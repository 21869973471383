import React from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import { updateWidgetAction } from "actions/widgets";
import styled from "styled-components";
import CloseIcon from "img/close-icon.svg";
import PropTypes from "prop-types";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Close = styled("div")`
  width: 30px;
  position: absolute;
  height: 30px;
  right: 1px;
  top: 1px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  font-size: 20px;

  background: white;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  transform: none !important;
  box-shadow: 0 0 10px 0 #e8e8e8;
  transition: 0.1s ease;

  & img {

    width: 15px;
    height: 15px;
  }
`;

const isArrayOfObjectsEqual = (x, y) => _.size(x) === _.size(y) && _.isEmpty(_.xorWith(x, y, _.isEqual));

export default class ResponsiveWidget extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      "currentBreakpoint": "lg",
      "compactType": "vertical",
      "mounted": false,
      "layouts": { "lg": props.initialLayout(props) }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
    this.onLayoutChanged = this.onLayoutChanged.bind(this);
    this.onNewLayout = this.onNewLayout.bind(this);
  }

  componentDidMount () {
    this.setState({ "mounted": true });
  }

  onBreakpointChange (breakpoint) {
    this.setState({
      "currentBreakpoint": breakpoint
    });
  }

  onCompactTypeChange () {
    const { "compactType": oldCompactType } = this.state;
    const compactType =
            oldCompactType === "horizontal"
              ? "vertical"
              : oldCompactType === "vertical"
                ? null
                : "horizontal";
    this.setState({ compactType });
  }

  onLayoutChanged (layouts) {
    const payload = _.filter(_.flatten(_.map(this.props.widgets, (item, index) => {
      return _.map(layouts, (obj, inx) => {
        if (inx === index) {
          return {
            "x": _.get(obj, ["x"]),
            "y": _.get(obj, ["y"]),
            "w": _.get(obj, ["w"]),
            "h": _.get(obj, ["h"]),
            "i": _.toString(inx),
            "static": false,
            "data": _.get(item, ["data"])
          };
        }
      });
    })), (item) => item);
    this.props.dispatch(updateWidgetAction(payload));
  }

  onNewLayout () {
    this.setState({
      "layouts": { "lg": generateLayout(this.props) }
    });
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!isArrayOfObjectsEqual(_.get(prevProps, ["widgets"]), _.get(this.props, ["widgets"]))) {
      this.onNewLayout();
    }
  }

  generateDOM (props) {
    const { dispatch, widgets } = props;
    const layouts = this.state.layouts.lg;
    return _.map(layouts, function (l, deleteIndex) {
      return (
                <div key={deleteIndex} className={"dashboard-item-content"}>
                    <Close
                        onClick={() => {
                          const confirmation = window.confirm("Вы уверены, что хотите удалить виджет?");
                          if (confirmation) {
                            const payload = _.filter(_.flatten(_.map(widgets, (item, index) => {
                              return _.map(layouts, (obj, inx) => {
                                if (inx === index && deleteIndex === index && deleteIndex === inx) {
                                  return null;
                                } else if (inx === index) {
                                  return {
                                    "x": _.get(obj, ["x"]),
                                    "y": _.get(obj, ["y"]),
                                    "w": _.get(obj, ["w"]),
                                    "h": _.get(obj, ["h"]),
                                    "i": _.toString(inx),
                                    "static": false,
                                    "data": _.get(item, ["data"])
                                  };
                                }
                              });
                            })), (item) => item);
                            dispatch(updateWidgetAction(payload));
                          }
                        }}
                    >
                        <img
                            alt={"close"}
                            src={CloseIcon}/>
                    </Close>
                    <span className="text">{_.get(l, ["data", "config", "name"], _.get(l, ["data", "name"]))}</span>
                </div>
      );
    });
  }

  render () {
    return (
            <div>
                <ResponsiveReactGridLayout
                    {...this.props}
                    layouts={this.state.layouts}
                    onBreakpointChange={this.onBreakpointChange}
                    resizeHandles={["n", "e", "s", "w", "ne", "se", "sw", "nw"]}
                    onResizeStop={this.onLayoutChanged}
                    onDragStop={this.onLayoutChanged}
                    onResize={this.onResize}
                    handleDragStop={this.handleDragStop}
                    measureBeforeMount={false}
                    isResizable={true}
                    useCSSTransforms={this.state.mounted}
                    rowHeight={100}
                    compactType={this.state.compactType}
                    preventCollision={!this.state.compactType}
                >
                    {this.generateDOM({
                      "dispatch": this.props.dispatch, "widgets": this.props.widgets
                    })}
                </ResponsiveReactGridLayout>
            </div>
    );
  }
}

ResponsiveWidget.propTypes = {
  "initialLayout": PropTypes.func,
  "dispatch": PropTypes.func,
  "widgets": PropTypes.array
};

ResponsiveWidget.defaultProps = {
  "className": "layout",
  "rowHeight": 30,
  "cols": { "lg": 12, "md": 12, "sm": 12, "xs": 12, "xxs": 12 },
  "initialLayout": (props) => generateLayout(props)
};

function generateLayout (props) {
  const { widgets } = props;
  return _.map(widgets, function (item, i) {
    return {
      "x": _.get(item, ["x"]),
      "y": _.get(item, ["y"]),
      "w": _.get(item, ["w"]),
      "h": _.get(item, ["h"]),
      "i": _.toString(i),
      "static": false,
      "data": _.get(item, ["data"])
    };
  });
}
