import { PivotViewComponent } from "@syncfusion/ej2-react-pivotview";
import * as React from "react";

const pivotData = [
  { "Sold": 31, "Amount": 52824, "Country": "France", "Products": "Mountain Bikes", "Year": "FY 2015", "Quarter": "Q1" },
  { "Sold": 51, "Amount": 86904, "Country": "France", "Products": "Mountain Bikes", "Year": "FY 2015", "Quarter": "Q2" },
  { "Sold": 90, "Amount": 153360, "Country": "France", "Products": "Mountain Bikes", "Year": "FY 2015", "Quarter": "Q3" },
  { "Sold": 25, "Amount": 42600, "Country": "France", "Products": "Mountain Bikes", "Year": "FY 2015", "Quarter": "Q4" },
  { "Sold": 27, "Amount": 46008, "Country": "France", "Products": "Mountain Bikes", "Year": "FY 2016", "Quarter": "Q1" }
];

function NewPivot () {
  const dataSourceSettings = {
    "columns": [{ "name": "Year", "caption": "Production Year" }, { "name": "Quarter" }],
    "dataSource": pivotData,
    "expandAll": false,
    "filters": [],
    "formatSettings": [{ "name": "Amount", "format": "C0" }],
    "rows": [{ "name": "Country" }, { "name": "Products" }],
    "values": [{ "name": "Sold", "caption": "Units Sold" }, { "name": "Amount", "caption": "Sold Amount" }]
  };
  return (<PivotViewComponent id='PivotView' height={350} dataSourceSettings={dataSourceSettings}></PivotViewComponent>);
};

export default NewPivot;
