import React from "react";
import styled from "styled-components";
import { Form } from "antd";
const MainContainer = styled(Form)`
  display: flex;
  & > *:not(:first-child) {
    margin-left: 15px;
  }

  ${({ style }) => style}
`;

const Fields = (props) => {
  return (
        <>
            <MainContainer {...props}/>
        </>
  );
};

Fields.useForm = Form.useForm;

export default Fields;
