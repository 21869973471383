export const USERS = "main/user";
export const AUTH_CONFIRM = `/${USERS}/auth-confirm/`;
export const DASHBOARD_DELETE = "/main/dashboard/%d/";
export const MAIN_DASHBOARDS_LIST = "/main/dashboard/";
export const MAIN_DASHBOARD_WIDGETS_LIST = "/main/dashboard/%d/get_stats/";
export const REPORT_MANY_CONFIG = "/saas/report_many_config/";
export const REPORT_MANY = "/saas/report_many/";
export const REPORT_CONFIG = "/main/report_config/";
export const MODEL_SEARCH_API = "/saas/for_select/";
export const WIDGETS_LIST = "/main/dashboard/select_stats/";
