const _ = require("lodash");

function getParsedQuery () {
  const urlString = window.location.href;
  if (!urlString || typeof urlString !== "string") {
    return {};
  }

  const queryStartIndex = urlString.indexOf("?");

  if (queryStartIndex === -1) {
    return {};
  }

  const queryString = urlString.substring(queryStartIndex + 1);
  const queryParams = queryString.split("&");

  return _.reduce(
    queryParams,
    (result, param) => {
      const [key, value] = param.split("=");
      result[key] = decodeURIComponent(value);
      return result;
    },
    {}
  );
}

export default getParsedQuery;
