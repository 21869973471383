export const theme = {
  "background": {
    "primary": "#fafafa",
    "active": "#f7f7f8",
    "light": {
      "inherit": "#FFFFFF00",
      "grey": "#EDEDF0",
      "white": "#ffffff",
      "primary": "#EBF2FF",
      "action": "#F61B58"
    }
  },
  "shape": {
    "borderRadius": "inherit"
  },
  "color": {
    "grey": "#545D7A",
    "light": {
      "dark_blue": "#4F71DC",
      "light_blue": "#76A9FF",
      "white": "#ffffff"
    },
    "dark": {
      "dark_grey": "#333333",
      "grey": "#5A5B6A",
      "light_grey": "#787885"
    }
  },
  "spacing": 0,
  "palette": {
    "text": {
      "primary": "rgba(0, 0, 0, 0.11)"
    },
    "primary": "#6a82c2",
    "primaryLight": "#8397cc",
    "primaryDark": "#5d73ad",
    "green": "#28a97c",
    "greenDark": "#249e74",
    "black": "#2e384c",
    "white": "#fff",
    "orange": "#fd8a74",
    "blue": "#2e80bb",
    "grey": "#999",
    "red": "#d64a54"
  },
  "button": {
    "border": "1px solid",
    "border_color": "#9DC2FF",
    "background": "#ffffff",
    "color": "#4F71DC"
  },
  "box_shadow": {
    "primary": "0px 2px 4px rgba(59, 69, 123, 0.2), 0px 4px 8px rgba(92, 107, 192, 0.2)",
    "none": "0 0 0 0 transparent",
    "spacing": "0px 2px 4px rgba(27, 78, 163, 0.2), 0px 4px 8px rgba(41, 121, 255, 0.2)"
  },
  "border": {
    "action_primary": "1px solid #F61B58",
    "color": {
      "primary": "#e1e1e1"
    },
    "radius": {
      "primary": "8px"
    }
  },
  // Components
  "card": {
    "width": "254px",
    "height": "490px",
    "padding": "16px",
    "price": {
      "font": {
        "height": "129.96%",
        "style": "normal",
        "weight": "600",
        "family": "var(--semi-bold)",
        "size": "16px",
        "color": "#333333",
        "spacing": "0.005em"
      }
    },
    "description": {
      "font": {
        "height": "135%",
        "weight": "normal",
        "family": "var(--regular)",
        "size": "16px",
        "color": "#3B3F4E",
        "spacing": "-0.02em"
      }
    }

  }
};
